@font-face {
  font-family: "OpenSans";
  src: url("OpenSans-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "OpenSans";
  src: url("OpenSans-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "OpenSans";
  src: url("OpenSans-ExtraBold.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "OpenSans";
  src: url("OpenSans-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "OpenSans";
  src: url("OpenSans-LightItalic.ttf") format("truetype");
  font-weight: normal;
  font-style: Italic;
}

@font-face {
  font-family: "OpenSans";
  src: url("OpenSans-Italic.ttf") format("truetype");
  font-weight: 300;
  font-style: Italic;
}

@font-face {
  font-family: "OpenSans";
  src: url("OpenSans-SemiBold.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}
